import axios from '@/api/axios';

const getTasksByManufactureId = async (id, area_id, search_value) => {
  const { data: response } = await axios.get(`/tasks-by-manufacture/${id}`, {
    params: {
      area_id,
      title: search_value,
    },
  });
  return response?.data;
};

const getTasksByDay = async (day) => {
  const { data: response } = await axios.get(`/calendar/tasks`, {
    params: {
      'filter[date]': day,
    },
  });
  return response?.data;
};

const updateTaskStatus = async (id, status_id) => {
  const { data: response } = await axios.post(`/tasks/${id}/change-status`, { status_id });
  return response;
};

const updateTaskOrder = async (id, data) => {
  const { data: response } = await axios.patch(`tasks/${id}/update-order`, { data });
  return response;
};

const updateSubtaskTaskOrder = async (deal_id, task_id, subtask_id, data) => {
  const { data: response } = await axios.put(
    `/deals/${deal_id}/tasks/${task_id}/subtask/${subtask_id}/subtasks`,
    { subtasks: data },
  );
  return response;
};

const removeSubtaskTaskOrder = async (deal_id, task_id, subtask_id, task_to_remove) => {
  const { data: response } = await axios.put(
    `/deals/${deal_id}/tasks/${task_id}/subtask/${subtask_id}/subtasks/remove-from-group`,
    { task_id: task_to_remove },
  );
  return response;
};

const addTask = async (data) => {
  const { data: response } = await axios.post(`/tasks/`, {
    product_id: data.product?.id,
    product_quantity: data.quantity,
    area_id: data.area?.id,
    user_ids: [data.user?.id],
  });
  return response;
};

const updateTask = async (data) => {
  const { data: response } = await axios.put(`/tasks/${data.id}`, {
    product_id: data.product?.id,
    product_quantity: data.quantity,
    area_id: data.area?.id,
    user_ids: [data.user?.id],
  });
  return response;
};

const createTask = async (data) => {
  const { data: response } = await axios.post(`/tasks/`, {
    product_id: data.product_id,
    product_quantity: data.quantity,
    manufacture_id: data.manufacture_id,
    area_id: data.area_id,
    total_time: data.need_time,
    stock_id: data.stock_id,
    user_ids: data.user_id,
    measurement_id: data.measurement_id,
  });
  return response.data;
};

export {
  getTasksByManufactureId,
  getTasksByDay,
  updateTaskStatus,
  addTask,
  updateTask,
  updateTaskOrder,
  updateSubtaskTaskOrder,
  removeSubtaskTaskOrder,
  createTask,
};
