import axios from '@/api/axios';

const getStock = async (page, search_value) => {
  const { data: response } = await axios.get('/stock', {
    params: {
      page,
      search_value,
    },
  });
  return response;
};

const getStockTitle = async (id) => {
  const { data: response } = await axios.get(`/stock/show/${id}`);
  return response.data;
};

const getAllStock = async () => {
  const { data: response } = await axios.get('/stock/all');
  return response.data;
};

const getStockByProduct = async (productId) => {
  const { data: response } = await axios.get(`/stock/${productId}`);
  return response.data;
};

const addStock = async (data) => {
  const { data: response } = await axios.post('/stock', data);
  return response;
};

const updateStock = async (data) => {
  const { data: response } = await axios.put(`/stock/${data.id}`, data);
  return response;
};

const deleteStock = async (id) => {
  const { data: response } = await axios.delete(`/stock/${id}`);
  return response;
};

const getStockMaterials = async (page, stock_id, search_value, type, product_type) => {
  const { data: response } = await axios.get('/stock/materials', {
    params: {
      page,
      stock_id,
      search_value,
      type,
      product_type,
    },
  });
  return response;
};

const getStockProductTypes = async () => {
  const { data: response } = await axios.get('/stock/product-types');
  return response.data;
};

const addStockMaterial = async (data) => {
  const { data: response } = await axios.post('/stock/materials', {
    title: data.title,
    stock_id: data.stock?.stock_id_c,
    quantity: data.total_quantity,
    measurement: data.measurement?.measurement_id_c,
  });
  return response;
};

const updateStockMaterial = async (data) => {
  const { data: response } = await axios.put(`/stock/materials/${data.id}`, {
    title: data.title,
    stock_id: data.stock?.stock_id_c,
    quantity: data.total_quantity,
    measurement: data.measurement?.measurement_id_c,
  });
  return response;
};

const deleteStockMaterial = async (id) => {
  const { data: response } = await axios.delete(`/stock/materials/${id}`);
  return response;
};

export {
  getStock,
  getStockTitle,
  getStockProductTypes,
  getAllStock,
  getStockByProduct,
  addStock,
  updateStock,
  deleteStock,
  getStockMaterials,
  addStockMaterial,
  updateStockMaterial,
  deleteStockMaterial,
};
