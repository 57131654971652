import axios from '@/api/axios';

const getProducts = async () => {
  const { data: response } = await axios.get(`/product`);
  return response?.data;
};

const getProductsByStockId = async (stock_id) => {
  const { data: response } = await axios.get(`/product/stock?stock_id=${stock_id}`);
  return response?.data;
};

const updateProductManufacture = async (data) => {
  const { data: response } = await axios.post(`/product/${data.id}`, data);
  return response.data;
};

export { getProducts, getProductsByStockId, updateProductManufacture };
