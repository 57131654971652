<template>
  <div
    ref="modalRef"
    class="modal fade"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" style="max-width: 740px">
      <form @submit.prevent.stop="onSubmitForm" class="modal-content">
        <div v-if="state.isDataLoading" class="modal-loading-wrapper">
          <div
            class="spinner-border text-light spinner-border-md ms-2"
            role="status"
          >
            <span class="visually-hidden">Загрузка...</span>
          </div>
        </div>

        <div class="modal-header flex-column">
          <div class="d-flex align-item-center justify-conyent-between w-100">
            <h5 class="main-form-control__title" id="staticBackdropLabel">
              {{ title }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              :disabled="state.isFormLoading"
            ></button>
          </div>

          <div class="main-small-alert w-100 mt-2">
            <inline-svg
              class="main-small-alert__icon"
              :src="require('@/assets/images/alert.svg')"
              fill="#717C99"
            />

            <p class="main-small-alert__text">
              Пункты помеченные символом <span class="text-danger">*</span>,
              обязательны для проверки/заполнения.
            </p>
          </div>
        </div>

        <div
          class="modal-body order-tables__modal-form-wrapper scrollbar-light"
          style="max-height: 65vh"
          :style="{ overflow: isSelectOpen ? 'hidden' : 'auto' }"
        >
          <div class="add-staff">
            <input class="visually-hidden" type="text" />

            <div class="main-form-control mb-3">
              <label class="main-form-control__label"> Продукт: </label>

              <v-select
                class="main-form-control__select task-select"
                :class="{
                  error: v$.product.$error,
                }"
                placeholder="Продукт"
                v-model="state.product"
                :options="productOptionsList"
                label="title"
                :reduce="(item) => item"
                :clearable="false"
                append-to-body
                :calculate-position="withPopper"
                :disabled="state.is_product"
              >
                {{ option.title }}
                <template
                  v-if="state.isDisabled"
                  #open-indicator="{ attributes }"
                >
                  <span v-bind="attributes"></span>
                </template>
              </v-select>

              <span class="error-warning" v-if="v$.product.$error"
                >* Данное поле обязательно</span
              >
            </div>

            <div class="main-form-control mb-3">
              <label class="main-form-control__label"> Кол-во: </label>

              <input
                class="main-form-control__input"
                :class="{
                  error: v$.quantity.$error,
                }"
                type="text"
                placeholder="Количество"
                v-model="state.quantity"
                :disabled="state.is_product"
              />

              <span class="error-warning" v-if="v$.quantity.$error"
                >* Данное поле обязательно</span
              >
            </div>

            <div class="main-form-control mb-3">
              <label class="main-form-control__label"> Цех: </label>

              <v-select
                class="main-form-control__select task-select"
                :class="{
                  error: v$.manufacture.$error,
                }"
                placeholder="Цех"
                v-model="state.manufacture"
                :options="manufactureOptionsList"
                label="title"
                :reduce="(item) => item"
                :clearable="false"
                append-to-body
                :calculate-position="withPopper"
                :disabled="state.isDisabled"
                @update:modelValue="
                  () => {
                    state.area = null;
                  }
                "
              >
                {{ option.title }}
                <template
                  v-if="state.isDisabled"
                  #open-indicator="{ attributes }"
                >
                  <span v-bind="attributes"></span>
                </template>
              </v-select>

              <span class="error-warning" v-if="v$.manufacture.$error"
                >* Данное поле обязательно</span
              >
            </div>

            <div class="mb-3">
              <label class="main-form-control__label"> Участок: </label>

              <v-select
                placeholder="Участок"
                label="title"
                class="main-form-control__select task-select"
                :class="{ error: v$.area.$error }"
                :options="state.sendAreaOptions"
                :reduce="(item) => item"
                :clearable="false"
                :calculate-position="withPopper"
                :disabled="!state.manufacture || state.isDisabled"
                v-model="state.area"
                append-to-body
              >
                {{ option.title }}
                <template v-slot:no-options>Ничего не найдено.</template>
                <template
                  v-if="state.isDisabled"
                  #open-indicator="{ attributes }"
                >
                  <span v-bind="attributes"></span>
                </template>
              </v-select>

              <span class="error-warning" v-if="v$.area.$error"
                >* Данное поле обязательно</span
              >
            </div>

            <!-- <div class="main-form-control mb-3">
              <label class="main-form-control__label"> Цех получатель: </label>

              <v-select
                class="main-form-control__select task-select"
                :class="{
                  error: v$.recivier_manufacture.$error,
                }"
                placeholder="Цех"
                v-model="state.recivier_manufacture"
                :options="manufactureOptionsList"
                label="title"
                :reduce="(item) => item"
                :clearable="false"
                append-to-body
                :calculate-position="withPopper"
                :disabled="state.isDisabled"
                @update:modelValue="
                  () => {
                    state.recivier_area = null;
                  }
                "
              >
                {{ option.title }}
                <template
                  v-if="state.isDisabled"
                  #open-indicator="{ attributes }"
                >
                  <span v-bind="attributes"></span>
                </template>
              </v-select>

              <span class="error-warning" v-if="v$.recivier_manufacture.$error"
                >* Данное поле обязательно</span
              >
            </div>

            <div class="mb-3">
              <label class="main-form-control__label">
                Участок получатель:
              </label>

              <v-select
                placeholder="Участок"
                label="title"
                class="main-form-control__select task-select"
                :class="{
                  error: v$.recivier_area.$error,
                }"
                :options="state.recipientAreaOptions"
                :reduce="(item) => item"
                :clearable="false"
                :calculate-position="withPopper"
                :disabled="!state.recivier_manufacture || state.isDisabled"
                v-model="state.recivier_area"
                append-to-body
              >
                {{ option.title }}
                <template v-slot:no-options>Ничего не найдено.</template>
                <template
                  v-if="state.isDisabled"
                  #open-indicator="{ attributes }"
                >
                  <span v-bind="attributes"></span>
                </template>
              </v-select>

              <span class="error-warning" v-if="v$.recivier_area.$error"
                >* Данное поле обязательно</span
              >
            </div> -->

            <div class="mb-3">
              <label class="main-form-control__label"> Время: </label>

              <VueDatePicker
                class="main-form-control__datepicker"
                :class="{
                  error: v$.need_time.$error,
                }"
                :clearable="false"
                :disabled="state.isDisabled || state.is_datepicker_disabled"
                :format="'ss:HH:mm'"
                position="right"
                placeholder="Время"
                v-model="state.need_time"
                auto-apply
                time-picker
                enable-seconds
              ></VueDatePicker>

              <span class="error-warning" v-if="v$.need_time.$error"
                >* Данное поле обязательно</span
              >
            </div>

            <div class="mb-3">
              <label class="main-form-control__label"> Исполнитель: </label>

              <v-select
                placeholder="Исполнитель"
                label="name"
                class="main-form-control__select"
                :class="{
                  error: v$.users.$error,
                }"
                :options="state.manufactureUserList"
                :reduce="(item) => item"
                :clearable="false"
                :calculate-position="withPopper"
                :loading="state.isManufactureUserListLoading"
                :disabled="!state.manufacture || state.isDisabled"
                v-model="state.users"
                append-to-body
                multiple
                @open="
                  getManufactureUserList(
                    state.manufacture?.id,
                    state.recivier_manufacture?.id
                  )
                "
              >
                {{ option.title }}
                <template v-slot:no-options>Ничего не найдено.</template>
                <template
                  v-if="state.isDisabled"
                  #open-indicator="{ attributes }"
                >
                  <span v-bind="attributes"></span>
                </template>
              </v-select>

              <span class="error-warning" v-if="v$.users.$error"
                >* Данное поле обязательно</span
              >
            </div>
          </div>

          <div class="main-form-control mb-3">
            <label class="main-form-control__label"> Склад: </label>

            <v-select
              class="main-form-control__select task-select"
              :class="{
                error: v$.stock.$error,
              }"
              placeholder="Склад"
              v-model="state.stock"
              :options="stockOptionsList"
              label="title"
              :reduce="(item) => item"
              :clearable="false"
              append-to-body
              :calculate-position="withPopper"
              :disabled="state.isDisabled"
            >
              {{ option.title }}
              <template v-slot:no-options>Ничего не найдено.</template>
              <template
                v-if="state.isDisabled"
                #open-indicator="{ attributes }"
              >
                <span v-bind="attributes"></span>
              </template>
            </v-select>

            <span class="error-warning" v-if="v$.stock.$error"
              >* Данное поле обязательно</span
            >
          </div>

          <div class="main-form-control mb-3">
            <label class="main-form-control__label"> Единица измерения: </label>

            <v-select
              class="main-form-control__select task-select"
              :class="{
                error: v$.measurement.$error,
              }"
              placeholder="Единица измерения"
              v-model="state.measurement"
              :options="measurementOptionsList"
              label="title"
              :reduce="(item) => item"
              :clearable="false"
              append-to-body
              :calculate-position="withPopper"
              :disabled="state.isDisabled"
            >
              {{ option.title }}
              <template v-slot:no-options>Ничего не найдено.</template>
              <template
                v-if="state.isDisabled"
                #open-indicator="{ attributes }"
              >
                <span v-bind="attributes"></span>
              </template>
            </v-select>

            <span class="error-warning" v-if="v$.measurement.$error"
              >* Данное поле обязательно</span
            >
          </div>
        </div>

        <div class="modal-footer">
          <div v-if="!state.isDisabled" class="d-flex w-100">
            <button
              type="button"
              class="btn btn-outline-white btn-md w-50 me-3"
              :disabled="state.isFormLoading"
              @click="onResetForm"
            >
              Сброс
            </button>
            <button
              type="submit"
              class="btn btn-gold btn-md w-50 ms-3"
              :disabled="state.isFormLoading"
              @click.stop
            >
              <span>Сохранить</span>

              <div
                v-if="state.isFormLoading"
                class="spinner-border spinner-border-sm ms-2"
                role="status"
              >
                <span class="visually-hidden">Загрузка...</span>
              </div>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { reactive, computed, watch } from "vue";

import useModal from "@/mixins/useModal";
import popperSelect from "@/mixins/popperSelect";

import { getManufactureAreaList as fetchManufactureAreaList } from "@/api/manufacture";
import { getManufactureUserList as fetchManufactureUserList } from "@/api/manufacture";
import { getStockByProduct } from "@/api/stock";

export default {
  mixins: [popperSelect],
  props: [
    "entityToUpdate",
    "title",
    "apiRequest",
    "manufactureOptionsList",
    "productOptionsList",
    "stockOptionsList",
    "measurementOptionsList",
    "refetch",
    "validation",
  ],
  emits: ["update:entityToUpdate", "updateTaskTime", "redirectToTask"],
  setup(props, { emit }) {
    const state = reactive({
      isFormLoading: false,
      isDataLoading: false,

      product: null,
      quantity: null,
      manufacture: null,
      area: null,
      recivier_manufacture: null,
      recivier_area: null,
      sendAreaOptions: [],
      recipientAreaOptions: [],
      stock: null,
      measurement: null,
      stockOptionsList: [],
      users: [],
      need_time: {
        seconds: 0,
        hours: 0,
        minutes: 0,
      },

      manufactureUserList: [],
      isManufactureUserListLoading: false,
    });

    const rules = computed(
      () =>
        props.validation || {
          product: {},
          quantity: {
            numeric: (value) => {
              if (value == null || value == "") return true;
              else if (value == 0) return false;
              else return /^\d+(\.\d+)?$/.test(value);
            },
          },
          manufacture: {},
          area: {},
          recivier_manufacture: {},
          recivier_area: {},
          stock: {},
          users: {},
          need_time: {},
          measurement: {},
        }
    );

    const { modalRef, v$, onResetForm, successSaveAlert, hideModal } = useModal(
      props,
      {
        state,
        rules,
      }
    );

    watch(
      () => state.manufacture,
      async () => {
        if (state.manufacture == null) return;

        state.sendAreaOptions = await fetchManufactureAreaList(
          state.manufacture.id
        );
      }
    );

    watch(
      () => state.recivier_manufacture,
      async () => {
        if (state.recivier_manufacture == null) return;

        state.recipientAreaOptions = await fetchManufactureAreaList(
          state.recivier_manufacture.id
        );
      }
    );

    watch(
      () => state.product,
      async () => {
        if (state.product == null) return;

        state.stockOptionsList = await getStockByProduct(state.product.id);
      }
    );

    async function onSubmitForm() {
      await this.v$.product.$touch();
      await this.v$.quantity.$touch();
      await this.v$.stock.$touch();
      await this.v$.users.$touch();
      await this.v$.need_time.$touch();
      await this.v$.manufacture.$touch();
      await this.v$.area.$touch();
      await this.v$.recivier_manufacture.$touch();
      await this.v$.recivier_area.$touch();
      await this.v$.measurement.$touch();
      if (
        this.v$.product.$invalid ||
        this.v$.area.$invalid ||
        this.v$.quantity.$invalid ||
        this.v$.stock.$invalid ||
        this.v$.users.$invalid ||
        this.v$.need_time.$invalid ||
        this.v$.manufacture.$invalid ||
        this.v$.recivier_manufacture.$invalid ||
        this.v$.area.$invalid ||
        this.v$.recivier_area.$invalid ||
        this.v$.measurement.$invalid
      )
        return;

      try {
        state.isFormLoading = true;
        const response = await props.apiRequest({
          task_id: props.entityToUpdate?.id,
          deal_id: props.entityToUpdate?.deal_id,
          manufacture_id: state.manufacture?.id,
          area_id: state.area?.id,
          recivier_manufacture_id: state.recivier_manufacture?.id,
          recivier_area_id: state.recivier_area?.id,
          product_id: state.product?.id,
          quantity: state.quantity,
          stock_id: state.stock?.stock_id_c,
          is_urgently: false,
          need_time: `${String(state.need_time?.seconds).padStart(
            2,
            "0"
          )}:${String(state.need_time?.hours).padStart(2, "0")}:${String(
            state.need_time?.minutes
          ).padStart(2, "0")}`,
          user_id: state.users?.map((user) => user?.id),
          order: state.order,
          measurement_id: state.measurement?.measurement_id_c,
        });

        successSaveAlert().then(async () => {
          if (props.refetch) {
            await props.refetch();
            emit("updateTaskTime", {
              taskId: state.task_id,
              subtaskId: state.subtask_id,
              parallelSubtaskId: state.id,
              taskOrder: state.task_order,
              subtaskOrder: state.subtask_order,
              parallelSubtaskOrder: 0,
            });
          }
          hideModal(modalRef.value);
          emit("redirectToTask", response);
        });
      } finally {
        state.isFormLoading = false;
      }
    }

    async function getManufactureUserList(
      manufactureId,
      receiverManufactureId
    ) {
      try {
        state.isManufactureUserListLoading = true;
        const userList = await fetchManufactureUserList(
          manufactureId,
          receiverManufactureId
        );
        state.manufactureUserList = userList;
      } finally {
        state.isManufactureUserListLoading = false;
      }
    }

    return {
      modalRef,
      state,
      v$,
      onSubmitForm,
      onResetForm,
      getManufactureUserList,
    };
  },
};
</script>

<style>
.v-select.drop-up.vs--open .vs__dropdown-toggle {
  border-radius: 0 0 4px 4px;
  border-top-color: transparent;
  border-bottom-color: rgba(60, 60, 60, 0.26);
}

[data-popper-placement="top"] {
  border-radius: 4px 4px 0 0;
  border-top-style: solid;
  border-bottom-style: none;
  box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15);
}

.vs__dropdown-menu {
  z-index: 100000 !important;
}

.task-select .vs__selected {
  width: 220px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  line-height: 37.5px;
}
</style>

<style lang="scss">
.dp__time_col_sec:nth-child(1) {
  order: 3;
}
.dp__time_col_sec:nth-child(2) {
  order: 2;
}
.dp__time_col_sec:nth-child(3) {
  order: 5;
}
.dp__time_col_sec:nth-child(4) {
  order: 4;
}
.dp__time_col_sec:nth-child(5) {
  order: 1;
}
</style>