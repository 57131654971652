import axios from '@/api/axios';

const getManufactureList = async () => {
  const { data: response } = await axios.get('/manufacture');
  return response.data;
};

const getManufactureAreaList = async (id) => {
  const { data: response } = await axios.get(`/manufacture/${id}/area`);
  return response.data;
};

const getManufactureUserList = async (id, addition_id) => {
  const { data: response } = await axios.get(`/manufacture/users`, {
    params: {
      manufactures: [id, addition_id],
    },
  });
  return response.data;
};

export { getManufactureList, getManufactureAreaList, getManufactureUserList };
