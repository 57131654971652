import { ref, toRef, reactive, onMounted, onUnmounted, watch } from 'vue';
import { useVuelidate } from '@vuelidate/core';

import { hideModal, disableModal, enableModal } from '@/helpers/bootstrap';
import { successSaveAlert } from '@/helpers/alerts';

export default function useModal(props, data) {
  const modalRef = ref(null);
  const entityToUpdate = toRef(props, 'entityToUpdate');
  const initialState = JSON.parse(JSON.stringify(data.state));
  const state = reactive(data.state);
  const v$ = useVuelidate(data.rules, state);

  onMounted(() => {
    modalRef.value.addEventListener('hidden.bs.modal', onResetForm);
  });

  onUnmounted(() => {
    removeEventListener('hidden.bs.modal', onResetForm);
  });

  watch(
    () => state.isFormLoading,
    () => {
      if (state.isFormLoading) disableModal(modalRef.value);
      else enableModal(modalRef.value);
    },
  );

  watch(entityToUpdate, () => {
    Object.assign(initialState, JSON.parse(JSON.stringify(entityToUpdate.value)));
    Object.assign(state, JSON.parse(JSON.stringify(entityToUpdate.value)));
  });

  function onResetForm() {
    Object.assign(state, JSON.parse(JSON.stringify(initialState)));
    v$.value.$reset();
  }

  async function onSubmitForm() {
    const isFormCorrect = await this.v$.$validate();
    if (!isFormCorrect || !props.apiRequest) return;
    state.isFormLoading = true;

    props
      .apiRequest(state)
      .then(() => {
        successSaveAlert().then(() => {
          if (props.refetch) props.refetch();
          hideModal(modalRef.value);
        });
      })
      .finally(() => {
        state.isFormLoading = false;
      });
  }

  return { modalRef, v$, onSubmitForm, onResetForm, hideModal, successSaveAlert };
}
